<template>
  <!-- <div class="report-container mt-3 mx-5"> -->
  <div class="report-container d-flex flex-column mt-3 mx-5" :style="format">
    <!-- <div class="report-container"> -->
    <!-- <div class="report-container mt-3"> -->
    <!-- <div v-if="isWatermarkShow" class="watermark">
        <h6 class="watermark-text">THIS DOCUMENT IS VERIFIED</h6>
      </div> -->
    <div class="d-flex align-items-center justify-content-between">
      <div>
        <img
          :src="institusi ? institusi.logo : `/${configSchool.logo}`"
          width="80px"
          height="60px"
          alt
        />
      </div>
      <div class="text-center">
        <h3 class="text-uppercase font-weight-bold font-size-18 mb-1">
          {{ institusi ? institusi.nama : configSchool.name2 }}
        </h3>
        <h3 class="font-size-10">
          {{ institusi ? institusi.alamat : configSchool.address }}
        </h3>
        <h3 class="font-size-10">
          {{ institusi ? institusi.no_hp : configSchool.number }}
        </h3>
      </div>
      <div class="invisible">
        <img
          :src="institusi ? institusi.logo : `/${configSchool.logo}`"
          width="70px"
          alt
        />
      </div>
    </div>
    <div class="hr" />
    <div class="text-center">
      <h3 class="text-uppercase font-weight-bold font-size-12">
        MID SEMESTER PROGRESS REPORT
      </h3>
      <h3 class="text-uppercase font-weight-bold font-size-10 mt-1">
        academic year
        {{ generalReport.kelas_tahun_ajaran.kela.tahun_ajaran.tahun }}
      </h3>
    </div>
    <table
      class="
        table table-borderless
        font-weight-bold font-size-10
        mt-2
        primaryTable
      "
    >
      <tr>
        <td class="text-uppercase">Name</td>
        <td>:</td>
        <td style="width: 55%">
          {{ generalReport.kelas_tahun_ajaran.murid.nama }}
        </td>
        <td class="text-uppercase">Number Student</td>
        <td>:</td>
        <td>{{ generalReport.kelas_tahun_ajaran.murid.nis }}</td>
      </tr>
      <tr>
        <td class="text-uppercase">Class</td>
        <td>:</td>
        <td>
          {{ generalReport.kelas_tahun_ajaran.kela.tingkat }}-{{
            generalReport.kelas_tahun_ajaran.kela.simbol
          }}
        </td>
        <td class="text-uppercase">NISN / SEMESTER</td>
        <td>:</td>
        <td>
          {{ generalReport.kelas_tahun_ajaran.murid.nisn }} /
          {{
            generalReport.kelas_tahun_ajaran.kela.tahun_ajaran.semester ===
            "Ganjil"
              ? "1"
              : "2"
          }}
        </td>
      </tr>
    </table>
    <table
      class="
        w-100
        table table-bordered table-responsive-sm
        font-size-10
        text-center
        primaryTable
      "
    >
      <tr>
        <td class="align-middle font-weight-bold" rowspan="2">NO</td>
        <td
          style="width: 30%"
          class="align-middle font-weight-bold"
          rowspan="2"
        >
          SUBJECT
        </td>
        <td class="align-middle font-weight-bold" rowspan="2">KBM</td>
        <td class="font-weight-bold" rowspan="1" colspan="4">KNOWLEDGE</td>
        <td class="font-weight-bold" rowspan="1" colspan="4">SKILL</td>
        <td class="font-weight-bold" rowspan="1" colspan="2">MID TEST</td>
      </tr>
      <tr>
        <td class="font-weight-bold" style="width: 7%" rowspan="1">1</td>
        <td class="font-weight-bold" style="width: 7%" rowspan="1">2</td>
        <td class="font-weight-bold" style="width: 7%" rowspan="1">3</td>
        <td class="font-weight-bold" style="width: 7%" rowspan="1">4</td>
        <td class="font-weight-bold" style="width: 7%" rowspan="1">1</td>
        <td class="font-weight-bold" style="width: 7%" rowspan="1">2</td>
        <td class="font-weight-bold" style="width: 7%" rowspan="1">3</td>
        <td class="font-weight-bold" style="width: 7%" rowspan="1">4</td>
        <td class="font-weight-bold" style="width: 7%" rowspan="1">Know</td>
        <td class="font-weight-bold" style="width: 7%" rowspan="1">Skill</td>
      </tr>
      <template class="border" v-for="group in generalReport.listGroups">
        <tr :key="group.id + 'header'" class="text-left">
          <td class="font-weight-bold" colspan="13">{{ group.nama }}</td>
        </tr>
        <tr
          v-for="(mapel, index1) in mapels(
            generalReport.report_mapels,
            group.nama
          )"
          :key="mapel.id"
        >
          <td class="align-middle">{{ numberIndex(index1) }}</td>
          <td class="text-left align-middle">
            <div
              class="pl-1"
              style="padding-top: 2px; padding-bottom: 2px !important"
            >
              {{
                mapel.mata_pelajaran.english_name !== null
                  ? mapel.mata_pelajaran.english_name
                  : mapel.mata_pelajaran.nama
              }}
            </div>
          </td>
          <td class="align-middle">
            {{
              mapel.mata_pelajaran.kbms.length
                ? mapel.mata_pelajaran.kbms[0].nilai
                : "-"
            }}
          </td>
          <td
            v-for="(item, index) in 4"
            :key="item + 'knowledge'"
            class="align-middle"
          >
            {{
              mapel.kd_murid_mapels.length &&
              mapel.kd_murid_mapels[index] &&
              mapel.kd_murid_mapels[index].knowledge_average !== null
                ? floatNumber(mapel.kd_murid_mapels[index].knowledge_average)
                : "-"
            }}
          </td>
          <td
            v-for="(item, index) in 4"
            :key="item + 'skill'"
            class="align-middle"
          >
            {{
              mapel.kd_murid_mapels.length &&
              mapel.kd_murid_mapels[index] &&
              mapel.kd_murid_mapels[index].skill_average !== null
                ? floatNumber(mapel.kd_murid_mapels[index].skill_average)
                : "-"
            }}
          </td>
          <td class="align-middle">
            {{ mapel.pts_knowledge ? floatNumber(mapel.pts_knowledge) : "-" }}
          </td>
          <td class="align-middle">
            {{ mapel.pts_skill ? floatNumber(mapel.pts_skill) : "-" }}
          </td>
        </tr>
      </template>
    </table>
    <table class="table table-bordered text-center font-size-10 primaryTable">
      <tr>
        <td class="align-middle font-weight-bold text-uppercase w-50">
          Total Score(s)
        </td>
        <td class="text-left" rowspan="1">
          <table class="borderless table table-borderless mb-0">
            <tr>
              <td class="w-50 invisible">1. Spiritual</td>
              <td style="width: 10px">:</td>
              <td style="width: 40px">
                {{
                  generalReport.total_score_reports
                    ? generalReport.total_score_reports
                    : "-"
                }}
              </td>
              <td class="invisible">days</td>
            </tr>
          </table>
        </td>
      </tr>
    </table>
    <table class="table table-bordered text-center font-size-10 primaryTable">
      <tr>
        <td class="align-middle font-weight-bold text-uppercase w-50">
          Average Report
        </td>
        <td class="text-left" rowspan="1">
          <table class="borderless table table-borderless mb-0">
            <tr>
              <td class="w-50 invisible">1. Spiritual</td>
              <td style="width: 10px">:</td>
              <td style="width: 40px">
                {{
                  generalReport.average_reports
                    ? generalReport.average_reports
                    : "-"
                }}
              </td>
              <td class="invisible">days</td>
            </tr>
          </table>
        </td>
      </tr>
    </table>
    <table class="table table-bordered text-center font-size-10 primaryTable">
      <tr>
        <td
          class="align-middle font-weight-bold text-uppercase w-50"
          rowspan="3"
        >
          Attendance
        </td>
        <td class="text-left" rowspan="1">
          <table class="borderless table table-borderless mb-0">
            <tr>
              <td class="w-50">1. Sick</td>
              <td style="width: 10px">:</td>
              <td style="width: 40px">{{ generalReport.sick }}</td>
              <td>day(s)</td>
            </tr>
          </table>
        </td>
      </tr>
      <tr>
        <td class="text-left" rowspan="1">
          <table class="borderless table table-borderless mb-0">
            <tr>
              <td class="w-50">2. Permit</td>
              <td style="width: 10px">:</td>
              <td style="width: 40px">{{ generalReport.excused }}</td>
              <td>day(s)</td>
            </tr>
          </table>
        </td>
      </tr>
      <tr>
        <td class="text-left" rowspan="1">
          <table class="borderless table table-borderless mb-0">
            <tr>
              <td class="w-50">3. Absent</td>
              <td style="width: 10px">:</td>
              <td style="width: 40px">{{ generalReport.absent }}</td>
              <td>day(s)</td>
            </tr>
          </table>
        </td>
      </tr>
    </table>
    <table class="table table-bordered text-center font-size-10 primaryTable">
      <tr>
        <td class="align-middle font-weight-bold text-uppercase w-50">
          Spiritual Attitude
        </td>
        <td class="text-left" rowspan="1">
          <table class="borderless table table-borderless mb-0">
            <tr>
              <td class="w-50 invisible">1. Spiritual</td>
              <td style="width: 10px">:</td>
              <td style="width: 40px">{{ generalReport.spiritual }}</td>
              <td class="invisible">days</td>
            </tr>
          </table>
        </td>
      </tr>
    </table>
    <table class="table table-bordered text-center font-size-10 primaryTable">
      <tr>
        <td
          class="align-middle font-weight-bold text-uppercase w-50"
          rowspan="6"
        >
          Social Attitude
        </td>
        <td class="text-left" rowspan="1">
          <table class="borderless table table-borderless mb-0">
            <tr>
              <td class="w-50">1. Honest</td>
              <td style="width: 10px">:</td>
              <td style="width: 40px">{{ generalReport.honest }}</td>
              <td class="invisible">days</td>
            </tr>
          </table>
        </td>
      </tr>
      <tr>
        <td class="text-left" rowspan="1">
          <table class="borderless table table-borderless mb-0">
            <tr>
              <td class="w-50">2. Discipline</td>
              <td style="width: 10px">:</td>
              <td style="width: 40px">{{ generalReport.discipline }}</td>
              <td class="invisible">days</td>
            </tr>
          </table>
        </td>
      </tr>
      <tr>
        <td class="text-left" rowspan="1">
          <table class="borderless table table-borderless mb-0">
            <tr>
              <td class="w-50">3. Politiness</td>
              <td style="width: 10px">:</td>
              <td style="width: 40px">{{ generalReport.politeness }}</td>
              <td class="invisible">days</td>
            </tr>
          </table>
        </td>
      </tr>
      <tr>
        <td class="text-left" rowspan="1">
          <table class="borderless table table-borderless mb-0">
            <tr>
              <td class="w-50">4. Self Confidence</td>
              <td style="width: 10px">:</td>
              <td style="width: 40px">{{ generalReport.self_confidence }}</td>
              <td class="invisible">days</td>
            </tr>
          </table>
        </td>
      </tr>
      <tr>
        <td class="text-left" rowspan="1">
          <table class="borderless table table-borderless mb-0">
            <tr>
              <td class="w-50">5. Caring</td>
              <td style="width: 10px">:</td>
              <td style="width: 40px">{{ generalReport.caring }}</td>
              <td class="invisible">days</td>
            </tr>
          </table>
        </td>
      </tr>
      <tr>
        <td class="text-left" rowspan="1">
          <table class="borderless table table-borderless mb-0">
            <tr>
              <td class="w-50">6. Responsible</td>
              <td style="width: 10px">:</td>
              <td style="width: 40px">{{ generalReport.responsible }}</td>
              <td class="invisible">days</td>
            </tr>
          </table>
        </td>
      </tr>
    </table>
    <table class="table table-bordered font-size-10 primaryTable">
      <tr class="text-center">
        <td class="font-weight-bold" colspan="6">
          RECAPITULATION OF REWARD AND PUNISHMENT
        </td>
      </tr>
      <tr class="text-center font-weight-bold">
        <td>REWARD</td>
        <td><div class="px-2">SCORE</div></td>
        <td><div class="px-2">FREQUENCY</div></td>
        <td>PUNISHMENT</td>
        <td><div class="px-2">SCORE</div></td>
        <td><div class="px-2">FREQUENCY</div></td>
      </tr>
      <tr v-for="(text, i) in 3" :key="i">
        <td
          :class="!rewards[i] ? ['text-center', 'align-middle'] : null"
          class="w-50"
        >
          <div class="pl-2">
            {{ rewards[i] ? rewards[i].reward_punishment.description : "-" }}
          </div>
        </td>
        <td class="align-middle text-center">
          {{ rewards[i] ? rewards[i].reward_punishment.score : "-" }}
        </td>
        <td class="align-middle text-center">
          {{ rewards[i] ? rewards[i].frequency : "-" }}
        </td>
        <td
          :class="!punishments[i] ? ['text-center', 'align-middle'] : null"
          class="w-50"
        >
          <div class="pl-2">
            {{
              punishments[i]
                ? punishments[i].reward_punishment.description
                : "-"
            }}
          </div>
        </td>
        <td class="align-middle text-center">
          {{ punishments[i] ? punishments[i].reward_punishment.score : "-" }}
        </td>
        <td class="align-middle text-center">
          {{ punishments[i] ? punishments[i].frequency : "-" }}
        </td>
      </tr>
      <tr class="font-weight-bold">
        <td class="text-center" colspan="4">
          RECAPITULATION OF REWARD AND PUNISHMENT POINT
        </td>
        <td class="text-center" colspan="2">
          {{ generalReport.total_rp_points }}
        </td>
      </tr>
    </table>
    <!-- <div class="ttd my-2 font-size-10 d-flex align-items-center justify-content-between"> -->
    <div
      class="
        ttd
        mt-1
        font-size-10
        d-flex
        justify-content-between
        qrcode
      "
    >
      <!-- <canvas id="canvas2QrCode"></canvas> -->
      <!-- {{base64QrCode}} -->
      <!-- <br />
            <small>*{{textCaption}}</small> -->
      <!-- {{base64QrCode}} -->
      <!-- {{base64QrCode}} -->
      <img
        class="qrcode-image"
        v-if="base64QrCode"
        :src="base64QrCode"
        alt="qrcode"
      />
      <div class="">
        <h3 class="text-center font-size-10 invisible">.</h3>
        <h3 class="text-center font-size-10">Parent</h3>
        <h3 class="text-center font-size-10 font-weight-bold mt-3 pt-5">
          ({{
            generalReport.kelas_tahun_ajaran.murid.orangtuas[0].nama
              ? generalReport.kelas_tahun_ajaran.murid.orangtuas[0].nama
              : "-"
          }})
        </h3>
      </div>
      <div class="">
        <h3 class="text-center font-size-10 invisible">.</h3>
        <h3 class="text-center font-size-10">Class Teacher</h3>
        <div
          class="text-center mt-3 font-size-18"
          :class="
            generalReport.verify_homeroom_date !== null ? '' : 'invisible'
          "
        >
          <!-- <a-icon id="iconSvg1" type="check-square" style="color: #041930;"/> -->
          <!-- <check-icon /> -->
          <img src="@/assets/checkbox.png" width="18" alt="verified" />
          <!-- <input class="checkVerification" type="checkbox" checked disabled> -->
        </div>
        <h3 class="text-center font-size-10 font-weight-bold mt-3 pt-1">
          ({{
            generalReport.kelas_tahun_ajaran.kela.guru.nama
              ? generalReport.kelas_tahun_ajaran.kela.guru.nama
              : "-"
          }})
        </h3>
      </div>
      <div class="">
        <h3 style="letter-spacing: 0.025em" class="text-center font-size-10">
          Jakarta, {{ publicationDate }}
        </h3>
        <h3 class="text-center font-size-10">Principal</h3>
        <div
          class="text-center mt-3 font-size-18"
          :class="
            generalReport.verify_principal_date !== null ? '' : 'invisible'
          "
        >
          <!-- <check-icon /> -->
          <img src="@/assets/checkbox.png" width="18" alt="verified" />
        </div>
        <h3 class="text-center font-size-10 font-weight-bold mt-3 pt-1">
          ({{ generalReport.kepsek.nama ? generalReport.kepsek.nama : "-" }})
        </h3>
      </div>
      <!-- <h3 class="text-center font-size-10 font-weight-bold mt-3 pt-5">({{generalReport.kepsek.nama ? generalReport.kepsek.nama : '-'}})</h3> -->
    </div>
    <!-- <br /> -->
    <!-- <div class="text-right">
            <small style="font-size: 8px;">*{{textCaption}}</small>
          </div> -->
    <!-- <h3 class="text-center font-size-10 font-weight-bold text-white mt-1">Lorem ipsum dolor sit amet consectetur adipisicing elit.</h3> -->
  </div>
</template>

<script>
import moment from 'moment'
import QRCode from 'qrcode'
// const CheckIcon = () => import('@/components/app/CheckIcon')
export default {
  data() {
    return {
      base64QrCode: null,
      textCaption: null,
      baseUrl: window.location.origin,
    }
  },
  // components: { CheckIcon },
  props: {
    configSchool: {
      type: Object,
    },
    generalReport: {
      type: Object,
      required: true,
    },
    format: {
      type: Object,
      // required: true,
    },
    // isWatermarkShow: {
    //   type: Boolean,
    //   required: true,
    // },
  },
  mounted() {
    // console.log('children', this.generalReport)
    this.generateBase64QrCode(`${this.generalReport.uuid}`)
    const guruWalas = this.generalReport.kelas_tahun_ajaran.kela.guru.nama ? this.generalReport.kelas_tahun_ajaran.kela.guru.nama : ''
    const kepalaSekolah = this.generalReport.kepsek.nama ? this.generalReport.kepsek.nama : ''
    this.textCaption = `This document was digitally signed by the principal${kepalaSekolah !== '' ? ` (${kepalaSekolah})` : ''}, and the class teacher${guruWalas !== '' ? ` (${guruWalas})` : ''}. No wet/physical signature required`
  },
  methods: {
    async generateBase64QrCode(uuid) {
      try {
      // console.log(this.base64IsReady)
        const link = this.baseUrl + '/public/report-preview/' + uuid
        this.base64QrCode = await QRCode.toDataURL(link)
        // const canvas = document.getElementsByClassName('qrcode')[0].getElementsByTagName('canvas')
        // const canvas = document.getElementsByClassName('qrcode')[0].getElementsByTagName('canvas')[0]
        // const canvas2 = document.getElementById('canvas2QrCode')
        // console.log(canvas, 'oaao', canvas2)
        // await QRCode.toCanvas(canvas2, link)
      // QRCode.toCanvas(canvass, link, (err, res) => {
      //   if (err) return console.log(err)
      //   console.log('success')
      // })
      // this.base64IsReady = true
      } catch (err) {
        console.log(err)
      }
    },
    moment,
    numberIndex(number) {
      return ++number
    },
    floatNumber(number) {
      return parseFloat(number).toFixed(2)
    },
    mapels(data, type) {
      if (!data.length) {
        return []
      }

      const find = data.filter(el => el.mata_pelajaran.kelompok.nama === type)

      return find
    },
  },
  computed: {
    publicationDate() {
      if (this.generalReport?.kelas_tahun_ajaran?.kela?.tahun_ajaran?.report_settings.length) {
        const report = this.generalReport.kelas_tahun_ajaran.kela.tahun_ajaran.report_settings.find(el => el.tipe === this.generalReport.tipe)
        if (report) {
          const date = moment(report.tanggak_publikasi).format('DD  MMMM YYYY')
          return date
        }
      }
      return '-'
    },
    today() {
      return moment().format('DD MMMM YYYY')
    },
    isMobile() {
      return this.$store.getters.isMobile
    },
    isTablet() {
      return this.$store.getters.isTablet
    },
    isDesktop() {
      return !this.isMobile && !this.isTablet
    },
    styleContainer() {
      if (this.format) {
        return { ...this.format }
      }
      return {}
    },
    institusi() {
      return this.$store.state.master.institusi
    },
    rewards() {
      let arr = []
      if (this.generalReport.report_reward_punishments.length) {
        arr = this.generalReport.report_reward_punishments.filter(rp => rp.reward_punishment.type === 'reward')
      }
      return arr
    },
    punishments() {
      let arr = []
      if (this.generalReport.report_reward_punishments.length) {
        arr = this.generalReport.report_reward_punishments.filter(rp => rp.reward_punishment.type === 'punishment')
      }
      return arr
    },
    loopRewardPunishment() {
      if (this.rewards > this.punishments) {
        return this.rewards
      } else {
        return this.punishments
      }
    },
  },
}
</script>

<style>
.checkVerification {
  margin: 0;
}
.checkVerification:disabled {
  cursor: default;
}

.report-container .primaryTable {
  margin-bottom: 5px !important;
  position: relative;
  z-index: 1;
}

.report-container .table td {
  padding: 1px !important;
}
.report-container .hr {
  border: 0.5px solid black !important;
  margin-top: 6px !important;
  margin-bottom: 6px !important;
}

.table-bordered {
  border: 0.5px solid black;
}

.table-bordered td {
  border: 0.5px solid black;
}

.borderless {
  border: none !important;
}

.borderless td {
  border: none !important;
}

.ttd .qrcode-image {
  min-width: 50px;
  min-height: 50px;
  max-width: 100px;
  max-height: 100px;
}

@media (min-width: 768px) {
  .ttd .qrcode-image {
    width: 100px;
    height: 100px;
  }
}

/* .watermark {
  position: absolute;
  z-index: 10;
  display: block;
  min-height: 50%;
  min-width: 50%;
}

.watermark-text {
  padding: 20%;
  color: lightgrey;
  font-size: 100px;
  transform: rotate(300deg);
  -webkit-transform: rotate(300deg);
} */
</style>
